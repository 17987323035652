import React from "react";
import Live from "../../pages/LiveVehicle/Live";
import ErrorPage from "../../pages/ErrorPage/ErrorPage";

function HomePage(props) {
  if (
    !localStorage.getItem("token") ||
    localStorage.getItem("exp") < Math.round(new Date().getTime() / 1000)
  ) {
    return <ErrorPage {...props} />;
  } else {
    return <Live {...props} />;
  }
}

export default HomePage;
