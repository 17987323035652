import React, { Suspense, Component } from "react";
/* eslint-disable import/first */
const Map = React.lazy(() => import("../../Map/Map"));
// import Map from '../../Map/Map'
import { DEVICE_LOCATION } from "../../graphql/subscriptions";
import withGoogleMaps from "../../hoc/withGoogleMaps";
import {
  Grid,
  Paper,
  Typography,
  withStyles,
  Divider,
} from "@material-ui/core";

import gql from "graphql-tag";
import moment from "moment";

import getCustomMarker from "../../Map/MapUtils/CustomMarker";
import getMultiLine from "../../Map/MapUtils/MultiLine";
// import { withApollo } from 'react-apollo'
import NavBar from "../NavBar/NavBar";
import { withApollo } from "react-apollo";

const GET_VEHICLE_DETAIL = gql`
  query($id: String) {
    getVehicleDetail(deviceUniqueId_fk: $id) {
      vehicleType
    }
  }
`;

const GET_LATEST_LOCATION = gql`
  query($id: String!) {
    latestLocation: getDeviceLatestLocation(deviceId: $id) {
      latitude
      longitude
      vehicleNumber
      address
      speed
      timestamp
      extBatVol
      haltStatus
      idlingStatus
      isNoGps
      isOverspeed
      isPrimaryBattery
      isOffline
      isNoData
    }
  }
`;

const styles = (theme) => ({
  root: {
    height: "100%",
  },
  appBar: {
    zIndex: theme.zIndex.drawer,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
  },
  flex: {
    flex: 1,
  },
});

/**
 * @summary Custom marker object
 */
let CustomMarker;
/**
 * @summary Custom multicolour polyline object
 */
let MultiLine;

/**
 * @summary Index to monitor progress of animation
 */
let count = 0;
/**
 * @summary Vehicle icon
 */
let markerInstance = null;
/**
 * @summary Animation loop
 */
let loop = null;
/**
 * @summary Flag to monitor if animation is active
 */
let isAnimationActive = false;

// let dId = localStorage.getItem('vehicle')

class Live extends Component {
  constructor(props) {
    super(props);
    CustomMarker = getCustomMarker(props.google);
    MultiLine = getMultiLine(props.google);
    // CustomPopup = getCustomPopup(props.google)
    // this._customPopup = new CustomPopup()
    // this.state = {
    // 	map: null
    // }
  }

  /**
   * @property {?object} map  Map object
   * @property {string} vehicleNumber vehicle number
   * @property {string} speed vehicle speed
   * @property {string} time last tracked time
   * @property {string} location last tracked location
   * @property {boolean} isLiveTracking
   */
  state = {
    map: null,
    vehicleNumber: "",
    speed: "0",
    time: "",
    location: "",
    isLiveTracking: false,
    id: localStorage.getItem("vehicle"),
    expired: false,
  };

  componentDidMount = () => {
    this.checkToken();
  };

  componentWillUnmount() {
    this.resetLiveTracking();
    this.stopSubscription();
    this.clearMapVariable();
  }

  setMap = (map) =>
    this.setState({ map }, () => {
      this.setState({ multiLine: new MultiLine(this.state.map) });
      this.handleRequestLive();
    });
  // ** LIVE TRACKING FUNCTIONS ** //-------------------------------------------------------------------
  isOffline = (timestamp) => {
    // timestamp is assumed to be UTC+0
    var d = new Date();
    var currentTime = Math.round(d.getTime() / 1000);
    return currentTime - parseInt(timestamp) > 1800;
  };

  setupSubscription = () => {
    // console.log("setup sub", this.props);
    this.deviceSubscription = this.props.client.subscribe({
      query: DEVICE_LOCATION,
      variables: {
        deviceId: this.state.id,
        snapToRoad: true,
      },
      fetchPolicy: "network-only",
      errorPolicy: "all",
    });
  };

  resetLiveTracking = () => {
    isAnimationActive = false;
    this.eta = [];
    count = 0;
    this.setState({
      livedata: {
        device: [],
        pointsReceived: 0,
      },
    });
    this.breakTimeout();
  };

  animateLive = () => {
    console.log("amination", this.state.liveData);

    const device = this.state.liveData.device;
    const pointsReceived = this.state.liveData.pointsReceived;
    if (markerInstance && device && pointsReceived > 0) {
      this.setState({
        speed: device[count].speed,
        time: device[count].timestamp,
        location: device[count].address,
      });
      console.log("device[count]", device[count]);
      markerInstance.updateMarker(
        {
          lat:
            device[count].latitude != null
              ? parseFloat(device[count].latitude.toFixed(6))
              : "12.0989876",
          lng:
            device[count].longitude != null
              ? parseFloat(device[count].longitude.toFixed(6))
              : "77",
        },
        {
          /* eslint-disable indent */
          status: this.isOffline(device[count].timestamp)
            ? "offline"
            : device[count].isNoGps
            ? "nogps"
            : device[count].haltStatus
            ? "halt"
            : device[count].idlingStatus === true
            ? "idle"
            : device[count].idlingStatus === false &&
              device[count].haltStatus === false
            ? "running"
            : "default",
          mode: "live",
          isOverspeed: device[count].isOverspeed,
          timestamp: device[count].timestamp,
          speed: device[count].speed,
          /* eslint-enable indent */
        },
        10000 / pointsReceived // Calculate interval when snapped points are received
      );
      if (count < pointsReceived - 1) {
        count = count + 1;
        loop = setTimeout(this.animateLive, 10000 / pointsReceived);
      } else {
        this.resetLiveTracking();
      }
    }
  };

  startSubscription = () => {
    this.setState({ isLiveTracking: true });
    this.unsubHandle = this.deviceSubscription.subscribe({
      next: ({ data }) => {
        this.setState({
          speed: data.deviceLiveTracking.speed,
          location: data.deviceLiveTracking.address,
          time: data.deviceLiveTracking.timestamp,
        });
        if (isAnimationActive) {
          this.resetLiveTracking();
          this.setState(
            {
              liveData: {
                device: data.deviceLiveTracking,
                pointsReceived: data.deviceLiveTracking.length,
              },
            },
            () => {
              count = 0;
              if (this.state.map) this.animateLive();
            }
          );
        } else {
          //   console.log("data", data);

          this.setState(
            {
              liveData: {
                device: data.deviceLiveTracking,
                pointsReceived: data.deviceLiveTracking.length,
              },
            },
            () => {
              count = 0;
              if (this.state.map) this.animateLive();
            }
          );
        }
      },
    });
  };

  stopSubscription = () => {
    this.setState({ isLiveTracking: false });
    if (this.unsubHandle) this.unsubHandle.unsubscribe();
  };

  handleRequestLive = async () => {
    this.setupSubscription();

    // Vehicle type for marker icon
    const response = await this.props.client.query({
      query: GET_VEHICLE_DETAIL,
      variables: {
        id: this.state.id,
      },
      errorPolicy: "all",
    });
    let vehicleType = "car";
    if (response.data) {
      vehicleType = response.data.getVehicleDetail.vehicleType;
    }

    // Get latest location before subscription to show marker
    let latitude = null;
    let longitude = null;
    const location = await this.props.client.query({
      query: GET_LATEST_LOCATION,
      variables: {
        id: this.state.id,
      },
    });

    let isOffline;
    let isNoGps;
    let haltStatus;
    let idlingStatus;
    let isNoData;

    if (location.data) {
      console.log("location.data", location.data);
      latitude = location.data.latestLocation.latitude;
      longitude = location.data.latestLocation.longitude;
      isOffline = location.data.latestLocation.isOffline;
      isNoGps = location.data.latestLocation.isNoGps;
      haltStatus = location.data.latestLocation.haltStatus;
      idlingStatus = location.data.latestLocation.idlingStatus;
      isNoData = location.data.latestLocation.isNoData;
      this.setState({
        liveData: {
          device: [
            {
              latitude: location.data.latestLocation.latitude,
              longitude: location.data.latestLocation.longitude,
            },
          ],
          pointsReceived: 1,
        },
        vehicleNumber: location.data.latestLocation.vehicleNumber,
        speed: location.data.latestLocation.speed,
        time: location.data.latestLocation.timestamp,
        location: location.data.latestLocation.address,
      });
    }

    // latitude ? latitude :
    //  longitude ? longitude :

    // Live marker
    markerInstance = new CustomMarker(
      {
        uniqueId: this.state.id,
        // latitude: "12.0988876",
        // longitude: "77.09887665",
        latitude,
        longitude,
        timestamp: moment().unix(),
        speed: 0,
        vehicleType: vehicleType,
        isOffline: isOffline,
        isNoGps: isNoGps,
        haltStatus: haltStatus,
        idlingStatus: idlingStatus,
        isNoData: isNoData,
      },
      this.state.map,
      this.state.multiLine
    );

    // Move to vehicle being tracked
    this.state.map.setCenter(
      new this.props.google.maps.LatLng(latitude, longitude)
    );

    // Start subscription for live tracking
    this.startSubscription();
  };

  handleCancelLiveTracking = () => {
    this.resetLiveTracking();
    this.stopSubscription();
    this.clearMapVariable();
  };

  clearMapVariable = () => {
    if (this.state.multiLine instanceof MultiLine) {
      this.state.multiLine.remove();
    }
    if (markerInstance) markerInstance.setMap(null);
    markerInstance = null;
  };

  breakTimeout = () => {
    if (loop) {
      clearTimeout(loop);
      loop = null;
    }
  };

  //check token
  checkToken = () => {
    // do whatever you like here

    if (
      !localStorage.getItem("token") ||
      localStorage.getItem("exp") < Math.round(new Date().getTime() / 1000)
    ) {
      this.setState({ expired: true });
      //   localStorage.clear();
      this.stopSubscription();
    }
    setTimeout(this.checkToken, 5000);
  };

  // ** LIVE TRACKING FUNCTIONS ** //-------------------------------------------------------------------
  render() {
    const { classes } = this.props;

    return (
      <Grid container spacing={2} className={classes.root}>
        <Grid item xs={12}>
          <Grid container spacin={2}>
            <Grid item xs={12}>
              <NavBar {...this.props} />
            </Grid>
          </Grid>
          <br />
          <br />
          {!this.state.expired ? (
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={9} lg={9}>
                <Suspense fallback={<div>Loading... </div>}>
                  <Map
                    google={this.props.google}
                    setMap={this.setMap}
                    zoom={16}
                  />
                </Suspense>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <br />
                <Paper
                  elevation={2}
                  style={{
                    // height: 450,
                    borderRadius: "10px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="h5">Information</Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{
                        margin: "10px",
                      }}
                    >
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        gutterBottom
                      >
                        Vehicle Number
                      </Typography>
                      <Typography variant="subtitle1" color="textPrimary">
                        {this.state.vehicleNumber
                          ? this.state.vehicleNumber
                          : "N/A"}
                      </Typography>
                      <Divider />
                      <br />
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        gutterBottom
                      >
                        Address
                      </Typography>
                      <Typography variant="subtitle1" color="textPrimary">
                        {this.state.location ? this.state.location : "N/A"}
                      </Typography>
                      <Divider />
                      <br />
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        gutterBottom
                      >
                        Speed (km/hr)
                      </Typography>
                      <Typography variant="subtitle1" color="textPrimary">
                        {this.state.speed}
                      </Typography>
                      <Divider />
                      <br />
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        gutterBottom
                      >
                        Last tracked time
                      </Typography>
                      <Typography variant="subtitle1" color="textPrimary">
                        {this.state.time
                          ? moment
                              .unix(this.state.time)
                              .format("Do MMMM YYYY h:mm:ss A")
                          : "N/A"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          ) : (
            <div
              style={{
                textAlign: "center",
              }}
            >
              <h1>Sorry !!! Token expired.</h1>
            </div>
          )}
        </Grid>
      </Grid>
    );
  }
}

export default withApollo(withGoogleMaps(withStyles(styles)(Live)));
