import React from 'react';
// import { ApolloProvider } from '@apollo/react-hooks'

import getApolloClient from './apollo/index'
// import { BrowserRouter, Route, Switch } from "react-router-dom"
import HomePage from './components/HomePage/index'
import { ApolloProvider } from 'react-apollo';
// import Live from '../src/pages/LiveVehicle/Live'

const client = getApolloClient()
function App() {
  return (
    // <BrowserRouter>
    //   <Switch>
    //   <Route exact path="/">
    //     <div><h1 style={{
    //       textAlign:'center'
    //     }}>Unauthorized !!!</h1></div>
    //   </Route>
	//     <Route exact path="/:token">
	<ApolloProvider client = {client}>
        <HomePage/>
	</ApolloProvider>
        //   <ApolloProvider client={client}>
        //     {/* <Live /> */}
        //   </ApolloProvider>
    //     </Route>	
    //   </Switch>
    // </BrowserRouter>
  );
}

export default App;

